import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	Typography, TextField, Grid, Select, MenuItem, FormControl, InputLabel, Chip, Button, FormControlLabel, Switch, FormGroup
} from "@mui/material";
import plusIcon from "../../../../assets/images/structure/plus-icon.svg";
import {getdataByKey} from "../../../helper/custom";
import GlobalService from "../../../../services/GlobalService";
import { API } from "../../../ENUM";
import { changeSnackbar } from "../../../../redux/reducers/snackbar";
import { changeLoader, currentLoader } from "../../../../redux/reducers/loader";
import uploadImg from "../../../../assets/images/structure/upload-img.png";
import EditIcon from "@mui/icons-material/Edit";
import constant from "../../../../constant";
import { ImageCropper, imagesToBase64 } from "../../../../dialog/Cropper";
import { blobToBase64 } from "../../../../pages/helper/custom";
import imageCompression from 'browser-image-compression';
const options = {
  maxSizeMB: 1,
  maxWidthOrHeight: 1920,
  useWebWorker: true,
  alwaysKeepResolution: true
}

const CategoryComponent = ({
	widgetIndex, businessTypes, type, widget, getWidgetData, showBusinessDropdown = false, changeWidgetStatus
}) => {

	const dragItem = useRef();
  const dragOverItem = useRef();
	const dispatch = useDispatch();
  const loader = useSelector(currentLoader);
	let [entities, setEntities] = useState([]);
  let [removedEntities, setRemovedEntities] = useState([]);
  let [widgetTypeId, setWidgetTypeId] = useState(0);
  let [categories, setCategories] = useState([]);
  let [subCategories, setSubCategories] = useState([]);
  let [subSubCategories, setSubSubCategories] = useState([]);
  let [cropperData, setCropperData] = useState({ croppedImage: "", isOpen: false });
	const [widgetTitle, setWidgetTitle] = useState("");
  const [widgetSubtitle, setWidgetSubtitle] = useState("");
  const [widgetTitleArabic, setWidgetTitleArabic] = useState("");
  const [widgetSubtitleArabic, setWidgetSubtitleArabic] = useState("");
  const [widgetTypeIdError, setWidgetTypeIdError] = useState('');
  const [widgetTitleError, setWidgetTitleError] = useState("");
  const [widgetSubtitleError, setWidgetSubtitleError] = useState("");
  const [widgetTitleArabicError, setWidgetTitleArabicError] = useState("");
  const [widgetSubtitleArabicError, setWidgetSubtitleArabicError] = useState("");
  let [selectedParentCategory, setSelectedParentCategory] = useState([]);
  let [selectedSubCategory, setSelectedSubCategory] = useState([]);
  let [selectedSubSubCategory, setSelectedSubSubCategory] = useState([]);
  const [widgetIcon, setWidgetIcon] = useState("");
  const [widgetBanner, setWidgetBanner] = useState("");
  const [titleGifEn, setTitleGifEn] = useState("");
  const [titleGifAr, setTitleGifAr] = useState("");

  const handleIconUpload = async (cropped) => {
    setCropperData({ croppedImage: "", isOpen: false, });
    const response = await GlobalService(API.WIDGET.UPLOAD_ICON, { image: cropped.croppedImage }, 'post');
    if (response?.ack === 1) {
      setWidgetIcon(response?.imageName);
    } else {
      dispatch(changeSnackbar({
        isOpen: true,
        state: "error",
        message: 'Something went wrong, please try again later'
      }))
    }
  }

	const editImageChangeHandler = async (e) => {
    const imageType = e.target.files[0].type;
    if (
      imageType === "image/png" ||
      imageType === "image/jpeg" ||
      imageType === "image/jpg"
    ) {
      const file = e.target.files[0];
      const compressedFile = await imageCompression(file, options);
      const imageUrl = await blobToBase64(compressedFile);
      cropperData = {
        croppedImage: imageUrl,
        isOpen: true,
      };
      setCropperData(cropperData);
      document.getElementById("upload-image" + widget?.id).value = "";
    }
  };

	const handleSaveWidgetData = async () => {
    dispatch(changeLoader(true));
    let isValid = true;

    if (widgetTitle === "") {
      setWidgetTitleError("Please enter widget title");
      isValid = false;
    }
    if (widgetSubtitle === "") {
      setWidgetSubtitleError("Please enter widget subtitle");
      isValid = false;
    }
    if (widgetTitleArabic === "") {
      setWidgetTitleArabicError("Please enter widget title in Arabic");
      isValid = false;
    }
    if (widgetSubtitleArabic === "") {
      setWidgetSubtitleArabicError("Please enter widget subtitle in Arabic");
      isValid = false;
    }
    if (widgetTypeId == 0 || widgetTypeId == null) {
      setWidgetTypeIdError("Please select business type");
      isValid = false;
    }
    if (widget?.id === undefined) {
      if (entities.length === 0) {
        dispatch(changeSnackbar({
          isOpen: true,
          state: "error",
          message: "Please select at least one category"
        }))
        isValid = false;
      }
    }
    if (isValid) {
      let data = {
        type_id: widgetTypeId,
        widget_name:widgetTitle,
        widget_name_ar:widgetTitleArabic,
        widget_desc:widgetSubtitle,
        widget_desc_ar:widgetSubtitleArabic,
        type: type,
        widget_icon: widgetIcon,
        slug: widget?.widget_slug,
        banner: widgetBanner,
        title_gif_en: titleGifEn,
        title_gif_ar: titleGifAr,
        grid_style: "thumbnail",
        entity:entities.map(
          (entity, index) => {
            return {entity_id: entity, sort_id: index + 1}
          }
        ),
        widget_style: 'category'
      }
      if (widget?.id) {
        data.id = widget?.id;
        if (removedEntities.length > 0) {
          data.removed_entity = removedEntities.join(",");
        }
      }
      const response = await GlobalService(API.WIDGET.UPDATE_ALL_WIDGET, data, 'post');
      if (response?.ack === 1) {
        dispatch(changeSnackbar({
          isOpen: true,
          state: "success",
          message: 'Widget saved successfully'
        }))
        getWidgetData();
      } else {
        dispatch(changeSnackbar({
          isOpen: true,
          state: "error",
          message: 'Something went wrong, please try again later'
        }))
      } 
    }
    dispatch(changeLoader(false));
  };

	const dragStart = (e, position) => {
    dragItem.current = position;
  };

  const dragEnter = (e, position) => {
    dragOverItem.current = position;
  };

  const drop = (e) => {
    const copyListItems = [...entities];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setEntities(copyListItems);
  };

	const getCategories = async () => {

    if (widgetTypeId !== 0) {
      const response = await GlobalService(API.WIDGET.GET_ALL_CATEGORIES(widgetTypeId));
      // get categories
      categories = response.data.map((category) => {
        return {
          id: category.id,
          name: category.category_locales[0].name,
          parent_id: category.parent_id,
        };
      });
      setCategories(categories);
      // get sub categories
      response.data.forEach(element => {
        const subData = element.sub.map ((sub) => {
          return {
            id: sub.id,
            name: sub.category_locales[0].name,
            parent_id: sub.parent_id,
          }
        });
        subCategories = [
          ...subCategories, ...subData
        ]
        // make sub categories unique
        subCategories = subCategories.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i);
      });
      setSubCategories(subCategories);
      // get sub sub categories
      response.data.forEach(element => {
        element.sub.forEach(sub => {
          const subSubData = sub.child.map ((subSub) => {
            return {
              id: subSub.id,
              name: subSub.category_locales[0].name,
              parent_id: subSub.parent_id,
            }
          });
          subSubCategories = [
            ...subSubCategories, ...subSubData
          ]
        });
      });
      // make sub sub categories unique
      subSubCategories = subSubCategories.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i);
      setSubSubCategories(subSubCategories);
    }
	}

	useEffect(() => {
    if (widget) {
      setWidgetTitle(getdataByKey(widget?.widget_locales, "en", "name"));
      setWidgetSubtitle(getdataByKey(widget?.widget_locales, "en", "description"));
      setWidgetTitleArabic(getdataByKey(widget?.widget_locales, "ar", "name"));
      setWidgetSubtitleArabic(getdataByKey(widget?.widget_locales, "ar", "description"));
      widgetTypeId = widget?.type_id;
      setWidgetTypeId(widgetTypeId);
      entities = widget?.widget_mappings?.sort(
        (a, b) => a.sort_id - b.sort_id
      )?.map((entity) => entity.entity_id) || [];
      setEntities(entities);
      setWidgetIcon(widget?.widget_icon);
      getCategories();
    }
  }, [widget]);
  
	return (
		<Grid
			container item spacing={2} xs={12}
			className="white-box-20-30 p-20"
		>
      <Grid item xs={12} className="d-flex justify-between ">
        <FormControl component="fieldset" variant="standard">
          <Typography variant="p" component="p" mb={0} fontWeight={500}>
            Widget {widgetIndex} - X Categories of a specific business type
          </Typography>
          <FormGroup>
            <FormControlLabel
              style={{ justifyContent: "flex-end", marginLeft: 0 }}
              value=""
              control={<Switch
                color="primary"
                checked={widget?.status === 'active' ? true : false}
                onChange={(e) => {
                  const data = {
                    id: widget?.id,
                    status: e.target.checked ? 'active' : 'inactive'
                  };
                  changeWidgetStatus(data);
                }}
              />}
              label="Change Widget status"
              labelPlacement="start"
            />
          </FormGroup>
        </FormControl>
				<div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", gap: 10, }}>
          <Button
            variant="contained" className="" color="info" style={{ whiteSpace: "nowrap" }}
            onClick={handleSaveWidgetData}
            disabled={loader}
          > Save </Button>
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className="upload-img mx-0 mt-1">
          {widgetIcon === "" ? <div
            className="before-upload" style={{ cursor: "pointer" }}
            onClick={() => { document.getElementById("upload-image" + widget?.id).click(); }}
          >
            <img src={uploadImg} alt=".." className="upload-icon" />
            <p className="upload-text">Upload Image</p>
            <input
              type="file" accept="image/png, image/jpeg" id={"upload-image" + widget?.id}
              onChange={editImageChangeHandler} style={{ position: "absolute", left: "-10000px" }}
            />
          </div>: <div className="after-upload">
            <EditIcon
              className="delete-icon"
              onClick={() => { setWidgetIcon(""); }}
            />
            <img
              alt=".." className="upload-icon" style={{ cursor: "pointer" }}
              onClick={() => { document.getElementById("upload-image" + widget?.id).click(); }}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = uploadImg;
              }}
              src={constant.IMG_BASE_URL + widgetIcon}
            />
            <input
              type="file" accept={`image/png, image/jpeg`} id={"upload-image" + widget?.id}
              onChange={editImageChangeHandler} style={{ position: "absolute", left: "-10000px" }}
            />
          </div>}
        </div>
      </Grid>
      <Grid container spacing={2} item xs={12}>
        <Grid item xs={4}>
          <TextField
            id="outlined-basic"
            label="Name of Widget - English"
            variant="outlined"
            fullWidth={true}
            inputProps={{ maxLength: 50 }}
						onChange={(e) => {
              setWidgetTitle(e.target.value)
              setWidgetTitleError('')
            }}
						value={widgetTitle}
          />
					<span style={{ color: "red" }}>{widgetTitleError}</span>
        </Grid>
        <Grid item xs={4}>
          <TextField
            id="outlined-basic"
            label="Subtitle of Widget - English"
            variant="outlined"
            inputProps={{ maxLength: 50 }}
            fullWidth={true}
						onChange={(e) => {
              setWidgetSubtitle(e.target.value)
              setWidgetSubtitleError('')
            }}
						value={widgetSubtitle}
          />
					<span style={{ color: "red" }}>{widgetSubtitleError}</span>
        </Grid>
      </Grid>
      <Grid container spacing={2} item xs={12}>
        <Grid item xs={4}>
          <TextField
            id="outlined-basic"
            label="Name of Widget - Arabic"
            variant="outlined"
            fullWidth={true}
            inputProps={{ maxLength: 50 }}
						onChange={(e) => {
              setWidgetTitleArabic(e.target.value)
              setWidgetTitleArabicError('')
            }}
						value={widgetTitleArabic}
          />
					<span style={{ color: "red" }}>{widgetTitleArabicError}</span>
        </Grid>
        <Grid item xs={4}>
          <TextField
            id="outlined-basic"
            label="Subtitle of Widget - Arabic"
            variant="outlined"
            fullWidth={true}
            inputProps={{ maxLength: 50 }}
						onChange={(e) => {
              setWidgetSubtitleArabic(e.target.value)
              setWidgetSubtitleArabicError('')
            }}
						value={widgetSubtitleArabic}
          />
					<span style={{ color: "red" }}>{widgetSubtitleArabicError}</span>
        </Grid>
      </Grid>
			{showBusinessDropdown?<>
        <Grid item xs={12}>
          <Typography variant="p" component="p" mb={0} fontWeight={500}>
            Choose Business Type
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <div className="d-flex items-center">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Business Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Business Type"
                value={widgetTypeId}
                onChange={(e) => {
                  widgetTypeId = e.target.value;
                  setWidgetTypeId(widgetTypeId);
                  setWidgetTypeIdError('');
                  if (widget?.type_id !== widgetTypeId) {
                    removedEntities = entities;
                    setRemovedEntities(removedEntities);
                    entities = [];
                    setEntities(entities);
                  } else {
                    removedEntities = [];
                    setRemovedEntities(removedEntities);
                    entities = widget?.widget_mappings?.map((entity) => entity.entity_id) || [];
                    setEntities(entities);
                  }
                  selectedParentCategory = [];
                  setSelectedParentCategory(selectedParentCategory)
                  selectedSubCategory = [];
                  setSelectedSubCategory(selectedSubCategory)
                  selectedSubSubCategory = [];
                  setSelectedSubSubCategory(selectedSubSubCategory)
                  getCategories();
                }}
              >
                {businessTypes?.map((type, index) => (
                  <MenuItem
                    value={type?.id}
                    key={`businessType-${index}`}
                  >{ type?.business_type_locales[0]?.name }</MenuItem>
                ))}
              </Select>
              <span style={{ color: "red" }}>{widgetTypeIdError}</span>
            </FormControl>
          </div>
        </Grid>
      </>: null}
      <Grid container spacing={2} item xs={12}>
        <Grid item xs={12}>
          <Typography
            variant="p"
            component="p"
            mb={0}
            fontWeight={500}
          >
            Choose Categories
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <div className="d-flex items-center">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Parent Category
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Parent Category"
                multiple={true}
                value={selectedParentCategory}
                onChange={(e) =>{
                  setSelectedParentCategory(e.target.value)
                  selectedSubCategory = [];
                  setSelectedSubCategory(selectedSubCategory)
                  selectedSubSubCategory = [];
                  setSelectedSubSubCategory(selectedSubSubCategory)
                }}
              >
                {categories?.filter(
                  (category) => !entities.includes(category.id)
                )?.map((category, index) => (
                  <MenuItem value={category?.id}
                    key={`parentCategory-${category?.id}`}
                  >{category?.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button>
              <img
                src={plusIcon} alt="" className=""
                onClick={() => {
                  if ((entities.length + selectedParentCategory.length) > 10) {
                    dispatch(changeSnackbar({
                      isOpen: true,
                      message: "You can't add more than 10 categories",
                      state: "error",
                    }));
                  } else {
                    setEntities([...entities, ...selectedParentCategory]);
                    setSelectedParentCategory([]);
                  }
                }}
              />
            </Button>
          </div>
        </Grid>
        {subCategories?.length > 0 &&
          <Grid item xs={4}>
          <div className="d-flex items-center">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Sub Category
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Sub Category"
                multiple={true}
                value={selectedSubCategory}
                onChange={(e) =>{
                  setSelectedSubCategory(e.target.value)
                  selectedSubSubCategory = [];
                  setSelectedSubSubCategory(selectedSubSubCategory)
                }}
              >
                {subCategories
                ?.filter((subCategory) =>
                  selectedParentCategory.includes(subCategory.parent_id) 
                  && !entities.includes(subCategory.id)
                )
                ?.map((subCategory, index) => (
                  <MenuItem value={subCategory?.id} key={`subCategory-${subCategory?.id}`}>{subCategory?.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button>
              <img
                src={plusIcon} alt="" className=""
                onClick={() => {
                  if ((entities.length + selectedSubCategory.length) > 10) {
                    dispatch(changeSnackbar({
                      isOpen: true,
                      message: "You can't add more than 10 categories",
                      state: "error",
                    }));
                  } else {
                    setEntities([...entities, ...selectedSubCategory]);
                    setSelectedSubCategory([]);
                  }
                }}
              />
            </Button>
          </div>
        </Grid>}
        {subSubCategories?.length > 0 &&
          <Grid item xs={4}>
          <div className="d-flex items-center">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Sub Sub Category
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Sub Sub Category"
                multiple={true}
                value={selectedSubSubCategory}
                onChange={(e) => {
                  setSelectedSubSubCategory(e.target.value)
                }}
              >
                {subSubCategories
                  ?.filter((subSubCategory) =>
                    selectedSubCategory.includes(subSubCategory.parent_id)
                    && !entities.includes(subSubCategory.id)
                  )
                  ?.map((subSubCategory, index) => (
                  <MenuItem value={subSubCategory?.id} key={`subSubCategory-${subSubCategory?.id}`}>{subSubCategory?.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button>
              <img
                src={plusIcon} alt="" className=""
                onClick={() => {
                  if ((entities.length + selectedSubSubCategory.length) > 10) {
                    dispatch(changeSnackbar({
                      isOpen: true,
                      message: "You can't add more than 10 categories",
                      state: "error",
                    }));
                  } else {
                    setEntities([...entities, ...selectedSubSubCategory]);
                    setSelectedSubSubCategory([]);
                  }
                }}
              />
            </Button>
          </div>
        </Grid>}
        <Grid item xs={12}>
          {entities.map((entity, index) => {
            let category = categories.find((category) => category.id === entity);
            if (!category) {
              category = subCategories.find((subCategory) => subCategory.id === entity);
            }
            if (!category) {
              category = subSubCategories.find((subSubCategory) => subSubCategory.id === entity);
            }
            return (
              <div
                style={{ float: "left", marginRight: "10px", marginBottom: "10px"}}
                key={`entity-${index}`}
                onDragStart={(e) => dragStart(e, index)}
                onDragEnter={(e) => dragEnter(e, index)}
                onDragEnd={drop}
                draggable
              >
                <Chip
                  label={category?.name}
                  onDelete={() => {
                    removedEntities.push(entity);
                    setRemovedEntities(removedEntities);
                    entities = entities.filter((item) => item !== entity);
                    setEntities(entities);
                  }}
                />
              </div>
            );
          })}
        </Grid>
      </Grid>
      {cropperData.isOpen ? (
        <ImageCropper
          cropperImage={cropperData.croppedImage}
          closepopup={() => { setCropperData({ croppedImage: "", isOpen: false });}}
          setCropImage={handleIconUpload}
          otherParams={{aspectRatio: 1/1,}}
        />
      ) : null}
    </Grid>
	)
};

export default CategoryComponent;