import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import deliverIcon from "../../../../assets/images/structure/deliver-icon.svg";
import calenderIcon from "../../../../assets/images/structure/calender-schedule.svg";
import DownloadIcon from "../../../../assets/images/structure/download-icon.svg";
import services from "../../../../services";
import { getdataByKey } from "../../../helper/custom";
import moment from "moment";
import Pagination from "@mui/material/Pagination";
import NoDataFound from "../../../../global-modules/EmptyPages/NoDataFound";
import OrderItem from "./component/OrderItem";
import { changeLoader } from "../../../../redux/reducers/loader";
import { useDispatch, useSelector } from "react-redux";
import { te } from "date-fns/locale";
import Item from "antd/es/list/Item";
import { currentSocketObject } from "../../../../redux/reducers/socket";
import { currentBaladiInfo } from "../../../../redux/reducers/baladiInfo";
// import {
//   changeSocketObject
// } from "../../../../redux/reducers/socketReducer";

const Orders = ({
  storeId,
  searchTerm,
  startDate,
  endDate,
  businessTypes,
  businessid,
  statusColors,
  socketRun,
  currentTab,
  resetSocketRun,
  checked,
  isBaladi,
  getOrderValue,
  threshold,
  baladiStoreTabChangeHandler,
  getOrderForBaladi,
  setCurrentCountTemp,
  updateCount
}) => {
  const dispatch = useDispatch();
  const [limit, setLimit] = useState(10);
  let [page, setPage] = useState(1);
  let [count, setCount] = useState();
  let [orderList, setOrderList] = useState([]);
  const navigate = useNavigate();
  const [dataaa, setDataaa] = useState({});
  const [otherStore, setOtherStore] = useState("mainStore");
  const { orederId } = useParams();
  const currentSocketObjectObj = useSelector(currentSocketObject);
  const currentBaladiInfoObj = useSelector(currentBaladiInfo);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const urlStoreType = urlSearchParams.get("isBaladi");
    let type = JSON.parse(urlStoreType);
    if (type != null) {
      baladiStoreTabChangeHandler(type);
    }
  }, []);


  const orderDetailPage = (orederId) => {
    const path = `/order-manage-detail/${getOrderValue.url}/${orederId}?tabtype=${isBaladi}&tab=${otherStore}`;
    navigate(path);
  };

  useEffect(() => {
    switch (getOrderForBaladi?.value) {
      case "scheduled_order":
        setOtherStore("scheduled_order");
        break;
      case "picking_in_progress":
        setOtherStore("picking_in_progress");
        break;
      case "qc_in_progress":
        setOtherStore("qc_in_progress");
        break;

      default:
        setOtherStore("mainStore");
        break;
    }
  }, [getOrderForBaladi]);

  const getAllOrders = async (getLoader=true) => {
    try {
      if (
        ( page === 1 ) || currentSocketObjectObj?.status === undefined
      ) { orderList = []; }
      dispatch(changeLoader(getLoader));
      const startDateTime = startDate
        ? startDate
        : moment(new Date()).format("YYYY-MM-DD 00:00:00");
      const endDateTime = endDate
        ?  endDate
        : moment(new Date(startDateTime)).format("YYYY-MM-DD 23:59:59");
      const query = new URLSearchParams({
        page: page,
        limit: checked == true ? 5000 : limit,
        threshold: threshold,
        storeId: isBaladi ? currentBaladiInfoObj.BALADI_STORE_SLUG : storeId ? storeId : "",
        type: getOrderValue?.value,
        search: searchTerm ? searchTerm.trim() : "",
        start_date: startDateTime ? startDateTime : "",
        end_date: endDateTime && startDateTime ? endDateTime : "",
        businessTypeId:
          !isBaladi && businessTypes && businessTypes.length > 0
            ? businessTypes?.map((item) => item.id).join(",")
            : "",
      }).toString();
      const response = (await services.Orders.GET_ALL_ORDERS(query)).data.orders;
      if (response) {
        let tempbusinessarray = [];
        setOrderList(response.rows);
        const optionnumber = checked === true ? 1000 : limit;
        count = Math.ceil(response.count / optionnumber);
        updateCount(response.count, getOrderValue?.value);
        setCount(count);
        updateCount(response.count, getOrderValue?.value);
        setCurrentCountTemp(response.count);
        response?.rows?.map((item) => {
          let lastModified = moment(item?.updatedAt).format("DD MMMM YYYY hh:mm a");
          const date1 = new Date(lastModified);
          const date2 = new Date();
          const diffTime = Math.abs(date2 - date1);
          const diffMinutes = Math.ceil(diffTime / (1000 * 60));
          if (diffMinutes > statusColors.time) {
            tempbusinessarray.push({
              storeName: getdataByKey(item?.store?.stores_locales, "en", "business_name"),
              businessName: item?.store?.business_address,
              ContactNumber: item?.store?.user?.contact_number,
              id: item?.id,
              orderDate: moment(item?.createdAt).format("DD MMMM YYYY hh:mm a"),
              delivery_date_time: moment(item?.delivery_date_time).format("DD MMMM YYYY hh:mm a"),
              OrderId: item?.order_id,
              CustomerName: item?.user?.full_name,
              TotalAmount: item?.total_amount,
              total_quantity: item?.total_quantity,
              statusColors: statusColors,
              deliver: item?.deliver,
              lastModified: lastModified,
              checked: checked,
            });
          }
        });
        setDataaa(tempbusinessarray);
      }
      dispatch(changeLoader(false));
    } catch (error) {
      dispatch(changeLoader(false));
    }
  };

  const handlePagination = (e, value) => {
    if (count >= value) {
      page = value;
      setPage(page);
      getAllOrders();
    }
  };

  // useEffect(() => {
  //   orderList = [];
  //   setOrderList(orderList);
  //   getAllOrders();
  // }, []);

  // useEffect(() => {
  //   page = 1;
  //   setPage(page);
  //   getAllOrders(storeId);
  // }, [searchTerm]);

  useEffect(() => {
    const getData = setTimeout(() => {
      page = 1;
      setPage(page);
      getAllOrders(storeId);
    }, 500);
    return () => clearTimeout(getData);
  }, [
    startDate, endDate, businessTypes, statusColors, checked,
    businessid, isBaladi, getOrderValue, threshold, currentTab,
    searchTerm, storeId
  ]);

  useEffect(() => {
    if (socketRun) {
      getAllOrders(false);
    }
  }, [socketRun]);

  return (
    <>
      {checked === false ? (
        <>
          {orderList && orderList.length > 0 ? (
            orderList?.map((item, index) => {
              let lastModified = moment(item?.updatedAt).format("DD MMMM YYYY hh:mm a");
              let orderdDate = moment(item?.createdAt).format("DD MMMM YYYY hh:mm a");
              if (item?.order_status_log !== "") {
                const statusLog = JSON.parse(item?.order_status_log)
                  ?.filter((item) => item.role === undefined)
                  ?.pop();
                lastModified = moment(statusLog?.updated_at).format("DD MMMM YYYY hh:mm a");
                const placed = JSON.parse(item?.order_status_log)?.find((item) => item?.status === "placed");
                orderdDate = moment(placed?.updated_at).format("DD MMMM YYYY hh:mm a");
              }
              return (
                <OrderItem
                  key={`order-${index}`}
                  id={item.id}
                  orderDetail={() => orderDetailPage(item.id)}
                  orderDate={orderdDate}
                  storeName={
                    // item?.store?.stores_locale?.business_name
                    getdataByKey( item?.store?.stores_locales, "en", "business_name")
                  }
                  businessName={item?.store?.business_address}
                  ContactNumber={item?.store?.user?.contact_number}
                  OrderId={`#${item?.order_id}`}
                  CustomerName={item?.user?.full_name}
                  total_quantity={item?.total_quantity}
                  TotalAmount={`QAR ${item.total_amount.toFixed(2)}`}
                  lastModified={lastModified}
                  statusLog={item?.order_status_log}
                  statusColors={statusColors}
                  orderStatus={item.status}
                  deliver={item?.deliver}
                  delivery_date_time={moment(item?.delivery_date_time).format("DD MMMM YYYY hh:mm a")}
                  checked={checked}
                  isBaladi={isBaladi}
                />
              );
            })
          ) :<NoDataFound text={"No Data Found"} />}
        </>
      ) : (
        <div>
          {dataaa?.map((i, index) => {
            let lastModified = moment(i?.updatedAt).format(
              "DD MMMM YYYY hh:mm a"
            );

            return (
              <OrderItem
                key={`order-${index}`}
                id={i?.id}
                orderDetail={() => orderDetailPage(i.id)}
                orderDate={i?.orderDate}
                storeName={i?.storeName}
                businessName={i?.businessName}
                ContactNumber={i?.ContactNumber}
                OrderId={`#${i?.OrderId}`}
                CustomerName={i?.CustomerName}
                total_quantity={i?.total_quantity}
                TotalAmount={`QAR ${i?.TotalAmount?.toFixed(2)}`}
                lastModified={lastModified}
                statusLog={item?.order_status_log}
                statusColors={statusColors}
                deliver={i?.deliver}
                delivery_date_time={i?.delivery_date_time}
                checked={checked}
              />
            );
          })}
        </div>
      )}

      {count > 1 ? (
        <Pagination
          count={count}
          page={page}
          onChange={(e, value) => handlePagination(e, value)}
          color="primary"
        />
      ) : null}
    </>
  );
};
export default Orders;
