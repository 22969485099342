import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Typography, TextField, Grid, Select, MenuItem,
  FormControl, InputLabel, Button, FormControlLabel, Switch, FormGroup
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {getdataByKey} from "../../../helper/custom";
import GlobalService from "../../../../services/GlobalService";
import { API } from "../../../ENUM";
import { changeSnackbar } from "../../../../redux/reducers/snackbar";
import { changeLoader, currentLoader } from "../../../../redux/reducers/loader";
import constant from "../../../../constant";

export default function BannerComponent({
  widgetIndex, businessTypes, widget, getWidgetData, showBusinessDropdown = false, type, changeWidgetStatus, showAllBanner
}) {
  
  const dragItem = useRef();
  const dragOverItem = useRef();
  const dispatch = useDispatch();
  const loader = useSelector(currentLoader);
  let [brands, setBrands] = useState([]);
	let [banners, setBanners] = useState([]);

  let [unavailableBusinessTypes, setUnavailableBusinessTypes] = useState([])
  let [widgetTypeId, setWidgetTypeId] = useState(0);
	const [searchAvailableBrands, setSearchAvailableBrands] = useState("");
  const [searchSelectedBrands, setSearchSelectedBrands] = useState("");
  
  const [widgetTitle, setWidgetTitle] = useState("");
  const [widgetSubtitle, setWidgetSubtitle] = useState("");
  const [widgetTitleArabic, setWidgetTitleArabic] = useState("");
  const [widgetSubtitleArabic, setWidgetSubtitleArabic] = useState("");
  
  const [widgetBanner, setWidgetBanner] = useState("");
  const [titleGifEn, setTitleGifEn] = useState("");
  const [titleGifAr, setTitleGifAr] = useState("");

  const [widgetTypeIdError, setWidgetTypeIdError] = useState('');
  const [widgetTitleError, setWidgetTitleError] = useState("");
  const [widgetSubtitleError, setWidgetSubtitleError] = useState("");
  const [widgetTitleArabicError, setWidgetTitleArabicError] = useState("");
  const [widgetSubtitleArabicError, setWidgetSubtitleArabicError] = useState("");
  
  let [newSelectedBrands, setNewSelectedBrands] = useState([]);
  let [removeSelectedBrands, setRemoveSelectedBrands] = useState([]);
  let [entities, setEntities] = useState([]);
  let [removedEntities, setRemovedEntities] = useState([]);
  const [showConfirmBox, setShowConfirmBox] = useState(false);

  const dragStart = (e, position) => {
    dragItem.current = position;
  };

  const dragEnter = (e, position) => {
    // smooth scrolling when dragging
    const current = document.getElementById(widget?.widget_slug);
    if (e.clientY > current.getBoundingClientRect().bottom) {
      const prev = document.getElementById(widget?.widget_slug + (position - 1));
      if (prev) {
        if (e.clientY > prev.getBoundingClientRect().bottom) {
          current.scrollTop += 50;
        }
        if (e.clientY < prev.getBoundingClientRect().top) {
          current.scrollTop -= 50;
        }
      }
    } else if (e.clientY < current.getBoundingClientRect().top) {
      const next = document.getElementById(widget?.widget_slug + (position + 1));
      if (next) {
        if (e.clientY > next.getBoundingClientRect().bottom) {
          current.scrollTop += 50;
        }
        if (e.clientY < next.getBoundingClientRect().top) {
          current.scrollTop -= 50;
        }
      }
    }
    dragOverItem.current = position;
  };

  const drop = (e) => {
    const copyListItems = [...entities];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setEntities(copyListItems);
  };

  const getBrands = async () => {
    dispatch(changeLoader(true));
    if (!widgetTypeId) {
      dispatch(changeLoader(false));
      return;
    };
    let response = null;
    if (showAllBanner)  {
      response = await GlobalService(API.WIDGET.GET_ALL_BANNERS_HOME + `${type === 'web_home'? '?type=web': ''}`);
    } else {
      response = await GlobalService(API.WIDGET.GET_ALL_BANNERS(widgetTypeId) + `${type === 'web_home'? '?type=web': ''}`);
    }
    banners = response?.data?.map((banner) => {
			let bannerType = ''
			if (banner.productId !== 0) {
				bannerType = 'Product'
			} else if (banner.categoryId !== 0) {
				bannerType = 'Category'
			} else if (banner.storeId !== 0) {
				bannerType = 'Store'
			} else {
				bannerType = 'Business Type'
			}
      return {
				id: banner.id,
				image: banner.image,
				type: banner.type,
				bannerType: bannerType
			};
    });
		setBanners(banners);
    dispatch(changeLoader(false));
  };

  const selectUnselectBrand = (brandId) => {
    const totalEntitues = entities?.length + newSelectedBrands?.length;
    if (newSelectedBrands?.includes(brandId)) {
      setNewSelectedBrands(newSelectedBrands.filter((id) => id !== brandId));
    } else {
      // if (totalEntitues >= 10){
      //   dispatch(changeSnackbar({
      //     isOpen: true,
      //     state: "error",
      //     message: "You can select maximum 10 brands"
      //   }))
      // } else {
        setNewSelectedBrands([...newSelectedBrands, brandId]);
      // }
    }
  }

  const selectUnselectRemoveBrand = (brandId) => {
    if (removeSelectedBrands?.includes(brandId)) {
      setRemoveSelectedBrands(removeSelectedBrands.filter((id) => id !== brandId));
    } else {
      setRemoveSelectedBrands([...removeSelectedBrands, brandId]);
    }
  }

  const handleForwardClick = () => {
    if (newSelectedBrands.length === 0) {
      dispatch(changeSnackbar({
        isOpen: true,
        state: "error",
        message: "Please select at least one brand"
      }))
    } else {
      setEntities([...entities, ...newSelectedBrands]);
      setNewSelectedBrands([]);
    }
  };

  const handleBackwardClick = () => {
    if (removeSelectedBrands.length === 0) {
      dispatch(changeSnackbar({
        isOpen: true,
        state: "error",
        message: "Please select at least one brand"
      }))
    } else {
      setRemovedEntities([...removedEntities, ...removeSelectedBrands]);
      entities = entities.filter((entity) => !removeSelectedBrands.includes(entity));
      setEntities(entities);
      setRemoveSelectedBrands([]);
    }
  };

  const handleSaveWidgetData = async () => {
    dispatch(changeLoader(true));
    let isValid = true;
    if (widgetTypeId == 0 || widgetTypeId == null) {
      setWidgetTypeIdError("Please select business type");
      isValid = false;
    }
    if (widget?.id === undefined) {
      if (entities.length === 0) {
        dispatch(changeSnackbar({
          isOpen: true,
          state: "error",
          message: "Please select at least one banner"
        }))
        isValid = false;
      }
    }
    if (isValid) {
      let data = {
        type_id: widgetTypeId,
        widget_name:widgetTitle,
        widget_name_ar:widgetTitleArabic,
        widget_desc:widgetSubtitle,
        widget_desc_ar:widgetSubtitleArabic,
        type: type,
        widget_icon: "",
        banner: widgetBanner,
        title_gif_en: titleGifEn,
        title_gif_ar: titleGifAr,
        slug: widget?.widget_slug,
        grid_style: "thumbnail",
        entity:entities.map(
          (entity, index) => {
            return {entity_id: entity, sort_id: index + 1}
          }
        ),
        widget_style: 'banner'
      }
      if (widget?.id) {
        data.id = widget?.id;
        if (removedEntities.length > 0) {
          data.removed_entity = removedEntities.join(",");
        }
      }
      const response = await GlobalService(API.WIDGET.UPDATE_ALL_WIDGET, data, 'post');
      if (response?.ack === 1) {
        dispatch(changeSnackbar({
          isOpen: true,
          state: "success",
          message: 'Widget saved successfully'
        }))
        getWidgetData();
      } else {
        dispatch(changeSnackbar({
          isOpen: true,
          state: "error",
          message: 'Something went wrong, please try again later'
        }))
      } 
    }
    dispatch(changeLoader(false));
  };

  useEffect(() => {
    if (widget) {
      setWidgetTitle(getdataByKey(widget?.widget_locales, "en", "name"));
      setWidgetSubtitle(getdataByKey(widget?.widget_locales, "en", "description"));
      setWidgetTitleArabic(getdataByKey(widget?.widget_locales, "ar", "name"));
      setWidgetSubtitleArabic(getdataByKey(widget?.widget_locales, "ar", "description"));
      widgetTypeId = widget?.type_id;
      setWidgetTypeId(widgetTypeId);
      entities = widget?.widget_mappings?.sort(
        (a, b) => a.sort_id - b.sort_id
      )?.map((entity) => entity.entity_id) || [];
      setEntities(entities);
      getBrands();
    }
  }, [widget]);

  return (
    <Grid
			container item spacing={2} xs={12}
			className="white-box-20-30 p-20"
		>
      <Grid item xs={12}  className="d-flex justify-between ">
        <FormControl component="fieldset" variant="standard">
          <Typography variant="p" component="p" mb={0} fontWeight={500} >
            Widget {widgetIndex} - X Banner of a specific business type
          </Typography>
          <FormGroup>
            <FormControlLabel
              style={{ justifyContent: "flex-end", marginLeft: 0 }}
              value=""
              control={<Switch
                color="primary"
                checked={widget?.status === 'active' ? true : false}
                onChange={(e) => {
                  const data = {
                    id: widget?.id,
                    status: e.target.checked ? 'active' : 'inactive'
                  };
                  changeWidgetStatus(data);
                }}
              />}
              label="Change Widget status"
              labelPlacement="start"
            />
          </FormGroup>
        </FormControl>
        <div style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: 10,
        }}>
          <Button
            variant="contained" className="" color="info" style={{ whiteSpace: "nowrap" }}
            onClick={handleSaveWidgetData}
            disabled={loader}
          > Save </Button>
        </div>
      </Grid>
      {showBusinessDropdown?<>
        <Grid item xs={12}>
          <Typography variant="p" component="p" mb={0} fontWeight={500}>
            Choose Business Type
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <div className="d-flex items-center">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Business Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Business Type"
                value={widgetTypeId}
                onChange={(e) => {
                  widgetTypeId = e.target.value;
                  setWidgetTypeId(widgetTypeId);
                  setWidgetTypeIdError('');
                  if (widget?.type_id !== widgetTypeId) {
                    removedEntities = entities;
                    setRemovedEntities(removedEntities);
                    entities = [];
                    setEntities(entities);
                  } else {
                    removedEntities = [];
                    setRemovedEntities(removedEntities);
                    entities = widget?.widget_mappings?.map((entity) => entity.entity_id) || [];
                    setEntities(entities);
                  }
                  getBrands();
                }}
              >
                {businessTypes?.filter((type) => !unavailableBusinessTypes?.includes(type.id))?.map((type, index) => (
                  <MenuItem
                    value={type?.id}
                    key={`businessType-${index}`}
                  >{ type?.business_type_locales[0]?.name }</MenuItem>
                ))}
              </Select>
              <span style={{ color: "red" }}>{widgetTypeIdError}</span>
            </FormControl>
          </div>
        </Grid>
      </>: null}

      <Grid container spacing={2} item xs={9}>
        <Grid item xs={5}>
          <div className="ranking-box">
            <div className="ranking-head">
              <Typography variant="h6" component="h6" fontSize={"16px"} fontWeight={500}>
								Available Banners
							</Typography>
            </div>
            <div className="ranking-content">
              {banners?.filter((banner) => !entities?.includes(banner.id))?.map((banner, index) => 
              <div
                className={`ranking-row ${ newSelectedBrands?.includes(banner.id) ? "active" :""}`}
                key={`banner-available-${index}`}
                onClick={() => selectUnselectBrand(banner.id) }  
              >
								<div
									style={{
										paddingTop: '10px',
										paddingBottom: '10px',
										display: 'flex',
										justifyContent: 'space-between',
									}}
								>
								<img
									src={constant.IMG_BASE_URL + banner?.image} alt={banner?.type}
									style={{ maxWidth: '100px', marginRight: '10px'}}
								/>  
								<span>
									<p>{banner?.type}</p>
									<p>{banner?.bannerType}</p>
								</span>
								</div>
							</div>)}
            </div>
          </div>
        </Grid>
        <Grid
					item={true} xs={1} display={"flex"} alignItems={"center"}
					justifyContent={"center"} flexDirection={"column"}
				>
          <ArrowForwardIcon onClick={handleForwardClick} />
          <ArrowBackIcon onClick={handleBackwardClick} />
        </Grid>
        <Grid item xs={5}>
          <div className="ranking-box">
            <div className="ranking-head">
              <Typography variant="h6" component="h6" fontSize={"16px"} fontWeight={500}>
                Selected Banners
              </Typography>
            </div>
            <div
              className="ranking-content"
              id={widget?.widget_slug}
            >
            {entities.map((entity, index) => {
              const banner = banners.find((banner) => banner?.id === entity);
              return (
              <div
                className={`ranking-row ${ removeSelectedBrands?.includes(banner?.id) ? "active" :""}`}
                key={`banner-available-${index}`}
                onClick={() => selectUnselectRemoveBrand(banner?.id) }  
                onDragStart={(e) => dragStart(e, index)}
                onDragEnter={(e) => dragEnter(e, index)}
                onDragEnd={drop}
                draggable
              > 
                <div
									style={{ paddingTop: '10px', paddingBottom: '10px', display: 'flex', justifyContent: 'space-between', }}
								>
                  <img
                    src={constant.IMG_BASE_URL + banner?.image} alt={banner?.type}
                    style={{ maxWidth: '100px', marginRight: '10px'}}
                  />  
                  <span>
                    <p>{banner?.type}</p>
                    <p>{banner?.bannerType}</p>
                  </span>
								</div>
              </div>)})}
            </div>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
}