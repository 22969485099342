import { useEffect, useState, useRef } from "react";
import {
  Button, Typography, TextField, Grid, FormControl, Chip, FormControlLabel, Switch, FormGroup
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import uploadImg from "../../../../assets/images/structure/upload-img.png";
import EditIcon from "@mui/icons-material/Edit";
import GlobalService from "../../../../services/GlobalService";
import constant from "../../../../constant";
import {getdataByKey} from "../../../helper/custom";
import { API } from "../../../ENUM";
import { changeSnackbar } from "../../../../redux/reducers/snackbar";
import { changeLoader, currentLoader } from "../../../../redux/reducers/loader";
import { ImageCropper as ImageCropperProd, imagesToBase64 } from "../../../../dialog/Cropper";
import { currentBaladiInfo } from "../../../../redux/reducers/baladiInfo";
import { blobToBase64, base64toFile } from "../../../../pages/helper/custom";
import imageCompression from 'browser-image-compression';
const options = {
  maxSizeMB: 1,
  maxWidthOrHeight: 1920,
  useWebWorker: true,
  alwaysKeepResolution: true
}

const ProductComponent = ({ widgetIndex, type, widget, getWidgetData, products, changeWidgetStatus }) => {

	const dragItem = useRef();
  const dragOverItem = useRef();
	const dispatch = useDispatch();
  const loader = useSelector(currentLoader);
  const baladiData = useSelector(currentBaladiInfo);
  const [widgetIcon, setWidgetIcon] = useState("");
	const [widgetTitle, setWidgetTitle] = useState("");
  const [widgetSubtitle, setWidgetSubtitle] = useState("");
  const [widgetTitleArabic, setWidgetTitleArabic] = useState("");
  const [widgetSubtitleArabic, setWidgetSubtitleArabic] = useState("");
  const [widgetTitleError, setWidgetTitleError] = useState("");
  const [widgetSubtitleError, setWidgetSubtitleError] = useState("");
  const [widgetTitleArabicError, setWidgetTitleArabicError] = useState("");
  const [widgetSubtitleArabicError, setWidgetSubtitleArabicError] = useState("");
  const [productSearch, setProductSearch] = useState("");
  const [isProductDivVisibleAutocomplate, setIsProductDivVisibleAutocomplate] = useState(false);
  let [productList, setProductList] = useState([]);
	let [entities, setEntities] = useState([]);
	let [widgetTypeId, setWidgetTypeId] = useState(0);
  let [removedEntities, setRemovedEntities] = useState([]);
  let [cropperDataP, setCropperDataP] = useState({ croppedImage: "", isOpen: false });
  const [widgetBanner, setWidgetBanner] = useState("");
  const [widgetBannerBackground, setWidgetBannerBackground] = useState("");
  const [widgetBannerBackgroundAr, setWidgetBannerBackgroundAr] = useState("");
  
  const [titleGifEn, setTitleGifEn] = useState("");
  const [titleGifAr, setTitleGifAr] = useState("");

  let [cropperDataPBanner, setCropperDataPBanner] = useState({ croppedImage: "", isOpen: false });

	const dragStart = (e, position) => {
    dragItem.current = position;
  };

  const dragEnter = (e, position) => {
    dragOverItem.current = position;
  };

  const drop = (e) => {
    const copyListItems = [...entities];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setEntities(copyListItems);
  };

  const handleSaveWidgetData = async () => {
    dispatch(changeLoader(true));
    let isValid = true;
    if (widgetTitle === "") {
      setWidgetTitleError("Please enter widget title");
      isValid = false;
    }
    if (widgetSubtitle === "") {
      setWidgetSubtitleError("Please enter widget subtitle");
      isValid = false;
    }
    if (widgetTitleArabic === "") {
      setWidgetTitleArabicError("Please enter widget title in Arabic");
      isValid = false;
    }
    if (widgetSubtitleArabic === "") {
      setWidgetSubtitleArabicError("Please enter widget subtitle in Arabic");
      isValid = false;
    }
    if (entities.length > 10) {
      dispatch(changeSnackbar({
        isOpen: true,
        state: "error",
        message: "You can select maximum 10 products"
      }))
      isValid = false;
    }
    if (widget?.id === undefined) {
      if (entities.length === 0) {
        dispatch(changeSnackbar({
          isOpen: true,
          state: "error",
          message: "Please select at least one product"
        }))
        isValid = false;
      }
    }
    if (isValid) {
      let data = {
        type_id: widgetTypeId,
        widget_name: widgetTitle,
        widget_name_ar: widgetTitleArabic,
        widget_desc: widgetSubtitle,
        widget_desc_ar: widgetSubtitleArabic,
        type: type,
        widget_icon: widgetIcon,
        banner: widgetBanner,
        background: widgetBannerBackground,
        backgroundAr: widgetBannerBackgroundAr,
        title_gif_en: titleGifEn,
        title_gif_ar: titleGifAr,
        slug: widget?.widget_slug,
        grid_style: "thumbnail",
        entity: entities.map((entity, index) => { return {entity_id: entity, sort_id: index + 1} }),
        widget_style: 'product'
      }
      if (widget?.id) {
        data.id = widget?.id;
        if (removedEntities.length > 0) {
          data.removed_entity = removedEntities.join(",");
        }
      }
      const response = await GlobalService(API.WIDGET.UPDATE_ALL_WIDGET, data, 'post');
      if (response?.ack === 1) {
        dispatch(changeSnackbar({
          isOpen: true,
          state: "success",
          message: 'Widget saved successfully'
        }))
        getWidgetData();
      } else {
        dispatch(changeSnackbar({
          isOpen: true,
          state: "error",
          message: 'Something went wrong, please try again later'
        }))
      } 
    }
    dispatch(changeLoader(false));
  };

  const handleIconUpload = async (cropped) => {
    setCropperDataP({ croppedImage: "", isOpen: false, });
    const response = await GlobalService(API.WIDGET.UPLOAD_ICON, { image: cropped.croppedImage }, 'post');
    if (response?.ack === 1) {
      setWidgetIcon(response?.imageName);
    } else {
      dispatch(changeSnackbar({
        isOpen: true,
        state: "error",
        message: 'Something went wrong, please try again later'
      }))
    }
  }

  const handleGifUpload = async (e) => {
    const imageType = e.target.files[0].type;
    if (
      imageType === "image/gif"
    ) {
      // const file = e.target.files[0];
      // const imageUrl = await imagesToBase64([file]);
      const formData = new FormData();
      formData.append('image', e.target.files[0]);
      formData.append('type', 'title_gif_en');
      const response = await GlobalService(API.WIDGET.UPLOAD_IMAGE, formData, 'post');
      if (response?.ack === 1) {
        setTitleGifEn(response?.image_name);
      } else {
        dispatch(changeSnackbar({
          isOpen: true,
          state: "error",
          message: 'Something went wrong, please try again later'
        }))
      }

    }
  }

  const handleArGifUpload = async (e) => {
    const imageType = e.target.files[0].type;
    if (
      imageType === "image/gif"
    ) {
      // const file = e.target.files[0];
      // const imageUrl = await imagesToBase64([file]);

      const formData = new FormData();
      formData.append('image', e.target.files[0]);
      formData.append('type', 'title_gif_en');

      const response = await GlobalService(API.WIDGET.UPLOAD_IMAGE, formData, 'post');
      if (response?.ack === 1) {
        setTitleGifAr(response?.image_name);
      } else {
        dispatch(changeSnackbar({
          isOpen: true,
          state: "error",
          message: 'Something went wrong, please try again later'
        }))
      }

    }
  }

  const handleBannerUpload = async (cropped) => {
    setCropperDataPBanner({ croppedImage: "", isOpen: false, isbackground:false ,  isbackgroundAr:false });

    const formData = new FormData();

    formData.append('image', base64toFile(cropped.croppedImage, 'banner'));
    formData.append('type', 'banner');
    
    const response = await GlobalService(API.WIDGET.UPLOAD_IMAGE, formData, 'post');
    if (response?.ack === 1) {
      setWidgetBanner(response?.image_name);
    } else {
      dispatch(changeSnackbar({
        isOpen: true,
        state: "error",
        message: 'Something went wrong, please try again later'
      }))
    }
  }

  const handleBannerUploadBackground = async (cropped) => {
    setCropperDataPBanner({ croppedImage: "", isOpen: false , isbackground:false ,  isbackgroundAr:false});
 
    const formData = new FormData();
    console.log("cropped.croppedImage", cropped.croppedImage);
    console.log(base64toFile(cropped.croppedImage, 'background'));
    formData.append('image', base64toFile(cropped.croppedImage, 'banner_background'));
    formData.append('type', 'banner');
    
    const response = await GlobalService(API.WIDGET.UPLOAD_IMAGE, formData, 'post');
    console.log("response", response);
    if (response?.ack === 1) {
      setWidgetBannerBackground(response?.image_name);
    } else {
      dispatch(changeSnackbar({
        isOpen: true,
        state: "error",
        message: 'Something went wrong, please try again later'
      }))
    }
  }
  const handleBannerUploadBackgroundAr = async (cropped) => {
    setCropperDataPBanner({ croppedImage: "", isOpen: false , isbackground:false ,  isbackgroundAr:false});
     
    const formData = new FormData();
    console.log("cropped.croppedImage", cropped.croppedImage);
    console.log(base64toFile(cropped.croppedImage, 'background'));
    formData.append('image', base64toFile(cropped.croppedImage, 'banner_backgroundAr'));
    formData.append('type', 'banner');
    
    const response = await GlobalService(API.WIDGET.UPLOAD_IMAGE, formData, 'post');
    console.log("response", response);
    if (response?.ack === 1) {
      setWidgetBannerBackgroundAr(response?.image_name);
    } else {
      dispatch(changeSnackbar({
        isOpen: true,
        state: "error",
        message: 'Something went wrong, please try again later'
      }))
    }
  }
	const editImageChangeHandler = async (e) => {
    const imageType = e.target.files[0].type;
    if (
      imageType === "image/png" ||
      imageType === "image/jpeg" ||
      imageType === "image/jpg"
    ) {
      const file = e.target.files[0];
      const compressedFile = await imageCompression(file, options);
      const imageUrl = await blobToBase64(compressedFile);
      cropperDataP = {
        croppedImage: imageUrl,
        isOpen: true,
        isbackground: false
      };
      setCropperDataP(cropperDataP);
      document.getElementById("upload-image" + widget?.id).value = "";
    }
  };

  const editBannerImageChangeHandlerBackground = async (e) => {
    const imageType = e.target.files[0].type;
    if (
      imageType === "image/png" ||
      imageType === "image/jpeg" ||
      imageType === "image/jpg"
    ) {
      const file = e.target.files[0];
      const compressedFile = await imageCompression(file, options);
      const imageUrl = await blobToBase64(compressedFile);
      cropperDataPBanner = {
        croppedImage: imageUrl,
        isOpen: true,
        isbackground: true,
        isbackgroundAr : false
      };
      setCropperDataPBanner(cropperDataPBanner);
      document.getElementById("upload-widget-background" + widget?.id).value = "";
    }
  };
  const editBannerImageChangeHandlerBackgroundAr = async (e) => {
    const imageType = e.target.files[0].type;
    if (
      imageType === "image/png" ||
      imageType === "image/jpeg" ||
      imageType === "image/jpg"
    ) {
      const file = e.target.files[0];
      const compressedFile = await imageCompression(file, options);
      const imageUrl = await blobToBase64(compressedFile);
      cropperDataPBanner = {
        croppedImage: imageUrl,
        isOpen: true,
        isbackground: true,
        isbackgroundAr : true
      };
      setCropperDataPBanner(cropperDataPBanner);
      document.getElementById("upload-widget-backgroundAr" + widget?.id).value = "";
    }
  };
  const editBannerImageChangeHandler = async (e) => {
    const imageType = e.target.files[0].type;
    if (
      imageType === "image/png" ||
      imageType === "image/jpeg" ||
      imageType === "image/jpg"
    ) {
      const file = e.target.files[0];
      const compressedFile = await imageCompression(file, options);
      const imageUrl = await blobToBase64(compressedFile);
      cropperDataPBanner = {
        croppedImage: imageUrl,
        isOpen: true,
        isbackground: false,
        isbackgroundAr : false
      };
      console.log("cropperDataPBanner", cropperDataPBanner);
      setCropperDataPBanner(cropperDataPBanner);
      document.getElementById("upload-widget-background" + widget?.id).value = "";
    }
  };

  const getAllProducts = async () => {
    try {
      let url = API.GET_ALL_PRODUCTS(productSearch);
      url += `&storeId=${baladiData.BALADI_STORE_Id}`;
      const response = await GlobalService(url);
      if (response?.ack === 1) {
        productList = response.data.map((item) => {
          return {
            id: item.id,
            name: item?.products_locales[0].title
          };
        });
        setProductList(productList);
        setIsProductDivVisibleAutocomplate(true);
      } else {
        productList = [];
        setProductList(productList);
        setIsProductDivVisibleAutocomplate(true);
      }
    } catch (error) {
      console.log("Error_In_store Listing", error);
    }
  };

  useEffect(() => {
    if (productSearch !== "") {
      const getData = setTimeout(() => {
        getAllProducts();
      }, 500);
      return () => clearTimeout(getData);
    } else {
      setProductList([]);
    }
  }, [productSearch]);

	useEffect(() => {
    if (widget) {
      setWidgetTitle(getdataByKey(widget?.widget_locales, "en", "name"));
      setWidgetSubtitle(getdataByKey(widget?.widget_locales, "en", "description"));
      setWidgetTitleArabic(getdataByKey(widget?.widget_locales, "ar", "name"));
      setWidgetSubtitleArabic(getdataByKey(widget?.widget_locales, "ar", "description"));
      console.log("widget", widget);
      setWidgetBanner(widget?.banner || "");
      setWidgetBannerBackground(widget?.background || "")
      setWidgetBannerBackgroundAr(widget?.backgroundAr || "")
      setTitleGifEn(widget?.title_gif_en || "");
      setTitleGifAr(widget?.title_gif_ar || "");

      widgetTypeId = widget?.type_id;
      setWidgetTypeId(widgetTypeId);
      entities = widget?.widget_mappings?.sort(
        (a, b) => a.sort_id - b.sort_id
      )?.map((entity) => entity.entity_id) || [];
      setEntities(entities);
      setWidgetIcon(widget?.widget_icon);
    }
  }, [widget]);

  return (
		<Grid
			container item spacing={2} xs={12}
			className="white-box-20-30 p-20"
		>
      <Grid item xs={12} className="d-flex justify-between ">
        <FormControl component="fieldset" variant="standard">
          <Typography variant="p" component="p" mb={0} fontWeight={500}>
						Widget {widgetIndex} - X Products of Baladi Landing Page
          </Typography>
          <FormGroup>
            <FormControlLabel
              style={{ justifyContent: "flex-end", marginLeft: 0 }}
              value=""
              control={<Switch
                color="primary"
                checked={widget?.status === 'active' ? true : false}
                onChange={(e) => {
                  const data = {
                    id: widget?.id,
                    status: e.target.checked ? 'active' : 'inactive'
                  };
                  changeWidgetStatus(data);
                }}
              />}
              label="Change Widget status"
              labelPlacement="start"
            />
          </FormGroup>
        </FormControl>
        <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", gap: 10, }}>
          <Button
            variant="contained" className="" color="info" style={{ whiteSpace: "nowrap" }}
            onClick={handleSaveWidgetData}
            disabled={loader}
          > Save </Button>
        </div>
      </Grid>
      <Grid item xs={12} className="d-flex justify-between gap-3">
        <div className="upload-img mx-0 mt-1">
          {widgetIcon === "" ? <div
            className="before-upload" style={{ cursor: "pointer" }}
            onClick={() => { document.getElementById("upload-image" + widget?.id).click(); }}
          >
            <img src={uploadImg} alt=".." className="upload-icon" />
            <p className="upload-text">Upload Image</p>
            <input
              type="file" accept="image/png, image/jpeg" id={"upload-image" + widget?.id}
              onChange={editImageChangeHandler} style={{ position: "absolute", left: "-10000px" }}
            />
          </div>: <div className="after-upload">
            <EditIcon className="delete-icon" onClick={() => { setWidgetIcon(""); }} />
            <img
              alt=".." className="upload-icon" style={{ cursor: "pointer" }}
              onClick={() => { document.getElementById("upload-image" + widget?.id).click(); }}
              onError={(e) => { e.target.onerror = null; e.target.src = uploadImg; }}
              src={constant.IMG_BASE_URL + widgetIcon}
            />
            <input
              type="file" accept={`image/png, image/jpeg`} id={"upload-image" + widget?.id}
              onChange={editImageChangeHandler} style={{ position: "absolute", left: "-10000px" }}
            />
          </div>}
        </div>
        <div className="upload-img mx-0 mt-1">
          {widgetBanner === "" ? <div
            className="before-upload" style={{ cursor: "pointer" }}
            onClick={() => { document.getElementById("upload-widgetBanner" + widget?.id).click(); }}
          >
            <img src={uploadImg} alt=".." className="upload-icon" />
            <p className="upload-text">Upload Widget Banner</p>
            <input
              type="file" accept="image/png, image/jpeg" id={"upload-widgetBanner" + widget?.id}
              style={{ position: "absolute", left: "-10000px" }} onChange={editBannerImageChangeHandler}
            />
          </div>: <div className="after-upload">
            <EditIcon className="delete-icon" onClick={() => { setWidgetBanner(""); }} />
            <img
              alt=".." className="upload-icon" style={{ cursor: "pointer" }}
              onClick={() => { document.getElementById("upload-widgetBanner" + widget?.id).click(); }}
              onError={(e) => { e.target.onerror = null; e.target.src = uploadImg; }}
              src={constant.IMG_BASE_URL + widgetBanner}
            />
            <input
              type="file" accept={`image/png, image/jpeg`} id={"upload-widgetBanner" + widget?.id}
              style={{ position: "absolute", left: "-10000px" }} onChange={editBannerImageChangeHandler}
            />
          </div>}
        </div>
        <div className="upload-img mx-0 mt-1">
          {widgetBannerBackground === "" ? <div
            className="before-upload" style={{ cursor: "pointer" }}
            onClick={() => { document.getElementById("upload-widget-background" + widget?.id).click(); }}
          >
            <img src={uploadImg} alt=".." className="upload-icon" />
            <p className="upload-text">Upload Widget Background</p>
            <input
              type="file" accept="image/png, image/jpeg" id={"upload-widget-background" + widget?.id}
              style={{ position: "absolute", left: "-10000px" }} onChange={editBannerImageChangeHandlerBackground}
            />
          </div>: <div className="after-upload">
            <EditIcon className="delete-icon" onClick={() => { setWidgetBannerBackground(""); }} />
            <img
              alt=".." className="upload-icon" style={{ cursor: "pointer" }}
              onClick={() => { document.getElementById("upload-widget-background" + widget?.id).click(); }}
              onError={(e) => { e.target.onerror = null; e.target.src = uploadImg; }}
              src={constant.IMG_BASE_URL + widgetBannerBackground}
            />
            <input
              type="file" accept={`image/png, image/jpeg`} id={"upload-widget-background" + widget?.id}
              style={{ position: "absolute", left: "-10000px" }} onChange={editBannerImageChangeHandlerBackground}
            />
          </div>}
        </div>
        <div className="upload-img mx-0 mt-1">
          {widgetBannerBackgroundAr === "" ? <div
            className="before-upload" style={{ cursor: "pointer" }}
            onClick={() => { document.getElementById("upload-widget-backgroundAr" + widget?.id).click(); }}
          >
            <img src={uploadImg} alt=".." className="upload-icon" />
            <p className="upload-text">Upload Widget Background Arabic</p>
            <input
              type="file" accept="image/png, image/jpeg" id={"upload-widget-backgroundAr" + widget?.id}
              style={{ position: "absolute", left: "-10000px" }} onChange={editBannerImageChangeHandlerBackgroundAr}
            />
          </div>: <div className="after-upload">
            <EditIcon className="delete-icon" onClick={() => { setWidgetBannerBackgroundAr(""); }} />
            <img
              alt=".." className="upload-icon" style={{ cursor: "pointer" }}
              onClick={() => { document.getElementById("upload-widget-backgroundAr" + widget?.id).click(); }}
              onError={(e) => { e.target.onerror = null; e.target.src = uploadImg; }}
              src={constant.IMG_BASE_URL + widgetBannerBackgroundAr}
            />
            <input
              type="file" accept={`image/png, image/jpeg`} id={"upload-widget-backgroundAr" + widget?.id}
              style={{ position: "absolute", left: "-10000px" }} onChange={editBannerImageChangeHandlerBackgroundAr}
            />
          </div>}
        </div>
      </Grid>
      <Grid item xs={12} className="d-flex justify-between gap-3">
        <div className="upload-img mx-0 mt-1 mr-5">
          {titleGifEn === "" ? <div
            className="before-upload" style={{ cursor: "pointer" }}
            onClick={() => { document.getElementById("upload-gif-eng" + widget?.id).click(); }}
          >
            <img src={uploadImg} alt=".." className="upload-icon" />
            <p className="upload-text">Upload English GIF</p>
            <input
              type="file" accept="image/gif" id={"upload-gif-eng" + widget?.id}
              onChange={handleGifUpload} style={{ position: "absolute", left: "-10000px" }}
            />
          </div>: <div className="after-upload">
            <EditIcon className="delete-icon" onClick={() => { setTitleGifEn(""); }} />
            <img
              alt=".." className="upload-icon" style={{ cursor: "pointer" }}
              onClick={() => { document.getElementById("upload-gif-eng" + widget?.id).click(); }}
              onError={(e) => { e.target.onerror = null; e.target.src = uploadImg; }}
              src={constant.IMG_BASE_URL + titleGifEn}
            />
            <input
              type="file" accept={`image/gif`} id={"upload-gif-eng" + widget?.id}
              onChange={handleGifUpload} style={{ position: "absolute", left: "-10000px" }}
            />
          </div>}
        </div>
        <div className="upload-img mx-0 mt-1 mr-5">
          {titleGifAr === "" ? <div
            className="before-upload" style={{ cursor: "pointer" }}
            onClick={() => { document.getElementById("upload-ar-gif-eng" + widget?.id).click(); }}
          >
            <img src={uploadImg} alt=".." className="upload-icon" />
            <p className="upload-text">Upload Arabic GIF</p>
            <input
              type="file" accept="image/gif" id={"upload-ar-gif-eng" + widget?.id}
              onChange={handleArGifUpload} style={{ position: "absolute", left: "-10000px" }}
            />
          </div>: <div className="after-upload">
            <EditIcon className="delete-icon" onClick={() => { setTitleGifAr(""); }} />
            <img
              alt=".." className="upload-icon" style={{ cursor: "pointer" }}
              onClick={() => { document.getElementById("upload-ar-gif-eng" + widget?.id).click(); }}
              onError={(e) => { e.target.onerror = null; e.target.src = uploadImg; }}
              src={constant.IMG_BASE_URL + titleGifAr}
            />
            <input
              type="file" accept={`image/gif`} id={"upload-ar-gif-eng" + widget?.id}
              onChange={handleArGifUpload} style={{ position: "absolute", left: "-10000px" }}
            />
          </div>}
        </div>
      </Grid>
      <Grid container spacing={2} item xs={12}>
        <Grid item xs={4}>
          <TextField
            id="outlined-basic" label="Name of Widget - English" variant="outlined"
            fullWidth={true} value={widgetTitle}
            inputProps={{ maxLength: 50 }}
            onChange={(e) => {
              setWidgetTitle(e.target.value)
              setWidgetTitleError('')
            }}
          />
          <span className="error">{widgetTitleError}</span>
        </Grid>
        <Grid item xs={4}>
          <TextField
            id="outlined-basic" label="Subtitle of Widget - English" variant="outlined"
            fullWidth={true} value={widgetSubtitle}
            inputProps={{ maxLength: 50 }}
            onChange={(e) => {
              setWidgetSubtitle(e.target.value)
              setWidgetSubtitleError('')
            }}
          />
          <span className="error">{widgetSubtitleError}</span>
        </Grid>
      </Grid>
      <Grid container spacing={2} item xs={12}>
        <Grid item xs={4}>
          <TextField
            id="outlined-basic" label="Name of Widget - Arabic" variant="outlined"
            fullWidth={true} value={widgetTitleArabic}
            inputProps={{ maxLength: 50 }}
            onChange={(e) => {
              setWidgetTitleArabic(e.target.value)
              setWidgetTitleArabicError('')
            }}
          />
          <span className="error">{widgetTitleArabicError}</span>
        </Grid>
        <Grid item xs={4}>
          <TextField
            id="outlined-basic" label="Subtitle of Widget - Arabic" variant="outlined"
            fullWidth={true} value={widgetSubtitleArabic}
            inputProps={{ maxLength: 50 }}
            onChange={(e) => {
              setWidgetSubtitleArabic(e.target.value)
              setWidgetSubtitleArabicError('')
            }}
          />
          <span className="error">{widgetSubtitleArabicError}</span>
        </Grid>
      </Grid>
      <Grid container spacing={2} item xs={12}>
        <Grid item xs={4}>
          <div className="d-flex items-center">
          <FormControl fullWidth>
            <TextField
              label="Select Product" value={productSearch} id="productSearch" autoComplete="off"
              placeholder="Enter your product name which you want to search"
              onClick={() => setIsProductDivVisibleAutocomplate(!isProductDivVisibleAutocomplate) }
              onChange={(e) => setProductSearch(e.target.value.trimStart()) }
            />
            {isProductDivVisibleAutocomplate && (
              <ul className="show-hide-div">
                {productList && productList.length > 0 ?
                productList?.map((item, index) => (
                <li
                  key={`product-${index}`}
                  onClick={() => {
                    console.log("item", item);
                    setEntities([...entities, item.id]);
                    setIsProductDivVisibleAutocomplate(!isProductDivVisibleAutocomplate);
                  }}
                >{item?.name}</li>)): null}
              </ul>
            )}
          </FormControl>
            {/* <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Choose Product</InputLabel>
              <Select
                labelId="demo-simple-select-label" id="demo-simple-select" label="Parent Category"
                multiple={true} value={selectedParentCategory}
                onChange={(e) => setSelectedParentCategory(e.target.value) }
              ><MenuItem value={0}>one</MenuItem></Select> 
            </FormControl>*/}
          </div>
        </Grid>
        <Grid item xs={12}>
          {entities?.map((entity, index) => {
            let product = products.find((pro) => pro.id === entity);
            return (
              <div
                style={{ float: "left", marginRight: "10px", marginBottom: "10px"}}
                key={`entity-${index}`}
                onDragStart={(e) => dragStart(e, index)}
                onDragEnter={(e) => dragEnter(e, index)}
                onDragEnd={drop}
                draggable
              >
                <Chip
                  label={product?.name} key={`entity-${index}`}
                  onDelete={() => {
                    removedEntities.push(entity);
                    setRemovedEntities(removedEntities);
                    entities = entities.filter((item) => item !== entity);
                    setEntities(entities);
                  }}
                />
              </div>
            );
          })}
        </Grid>
      </Grid>
  	  {/* <Grid item xs={12}>
  	    <img
  	      src={landingScreen2}
  	      alt=""
  	      className=""
  	      style={{
  	        width: 400,
  	        height: 'auto',
  	        objectFit: "contain",
  	        borderRadius: 16,
  	      }}
  	    />
  	  </Grid> */}
      {cropperDataP.isOpen ? (
        <ImageCropperProd
          cropperImage={cropperDataP.croppedImage}
          closepopup={() => { setCropperDataP({ croppedImage: "", isOpen: false });}}
          setCropImage={handleIconUpload}
          otherParams={{aspectRatio: 1/1,}}
        />
      ) : null}

      {cropperDataPBanner.isOpen ? (
      <ImageCropperProd
          cropperImage={cropperDataPBanner.croppedImage}
          closepopup={() => setCropperDataPBanner({ croppedImage: "", isOpen: false })}
          setCropImage={
            cropperDataPBanner?.isbackground 
              ?  cropperDataPBanner?.isbackgroundAr ? handleBannerUploadBackgroundAr :  handleBannerUploadBackground 
              : handleBannerUpload
          }
          otherParams={{ aspectRatio: 6 / 4 }}
        />

      ) : null}

  	</Grid>
  );
};

export default ProductComponent;