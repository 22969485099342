export const GOOGLE_GEOCODE_API_KEY =
  "AIzaSyAAB45EPGbb-F1CAULCyQxZIMvDdGJH8aA-(commented)";
export const API_GET_GEOLOCATION = `https://services.gisqatar.org.qa/server/rest/services/Vector/QARS1/MapServer/0/query`;
export const VALIDATE_COORDINATES = `https://epsg.io/trans`;
export const X_API_KEY = "baladi_dev_KHYUIS7L3xa07dsfgdyWA8dMRRsFkc1sfd3432jLB"; // staging
// export const X_API_KEY = "baladi_dev_pX2Rldl9LSFlVSVM3TDN4YTA3ZHNmZ2R5V0E4ZE1S"; // development

export const HEADERS = {
  "x-api-key": X_API_KEY,
  'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
};

export const MONTH = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const API = {
  GET_BALADI_INFO: "/auth/get-baladi-info",
  ADD_LOCATION: `/location/add`,
  GET_LOCATION: `/location/get`,
  UPDATE_LOCATION: `/location/update`,
  GET_LOCATION_BY_ID: (locationId) => `/location/get-location-by-id/${locationId}`,
  DELETE_LOCATION: "/location/delete",
  ADD_ASSOCIATES: "/stores/add-associates",
  GET_ASSOCIATES: "/stores/get-associates",
  GET_ALL_BRANDS: (search) => `/coupon-codes/get-all-brand-list?search=${search}`,
  GET_ALL_STORES: (search) => `/coupon-codes/get-all-store-list?search=${search}`,
  GET_ALL_STORES_CATEGORIES: (search) => `/coupon-codes/get-all-store-category-list?search=${search}`,
  GET_ALL_PRODUCTS: (search) => `/coupon-codes/get-all-products-list-by-categories?search=${search}`,
  GET_ALL_BUSINESS: "/business-type/get-business-type-by-query",
  ADD_COUPONS: "/coupon-codes/add",
  GET_ALL_COUPONS: "/coupon-codes/get-all-coupon-codes",
  UPDATE_COUPON_STATUS: (couponId, status) => `/coupon-codes/change-status/${couponId}/${status}`,
  GET_COUPON_DETAIL: (couponId) => `/coupon-codes/specific/${couponId}`,
  UPDATE_COUPONS: (couponId) => `/coupon-codes/edit/${couponId}`,
  GET_STORE_BY_CATEGORY: (categoryId) => `/site-setting/get-all-store/${categoryId}`,
  CATEGORIES_STORE_RELATED: (categoryId) => `/store-category-realtion/get-categories/${categoryId}`,
  STORE_CATEGORIES: (categoryId) => `/store-category-realtion/get-brand-categories/${categoryId}`,
  GET_ALL_BRANDS_BY_CATEGORY: (categoryId) => `/stores/get-all-brands-list-for-dropdown?businessTypeId=${categoryId}`,
  GET_ALL_USER_ROLES: "/users/role/all",
  GET_STORE_BY_ID: (storeId) => `/stores/get-store-by-id/${storeId}`,
  GET_ALL_PARENT_CATEGORIES: (businessId, parent) => `/category/get-categories-by-query?businessTypeId=${businessId}&parent_id=${parent}`,
  GET_ALL_SUB_SUB_CATEGORIES: (parent) => `/category/get-categories-by-query?parent_id=${parent}`,
  GET_ADD_CATEGORY_TO_STORE: `/store-category-realtion/add`,
  UPDATE_CATEGORY_OF_STORE: `/store-category-realtion/update-category-new`,
  UPDATE_CATEGORY_SEQUENCE: `/store-category-realtion/change-sequence-new`,
  GET_PRODUCTS_FOR_CATEGORIES: `/store-category-realtion/get-all-products`,
  UPDATE_STATUS_BY_ID: (id) => `/products/update-status-by-id/${id}`,
  CATEGORIES_STORE_DELETE_JSON: (id) => `/category/store-category-json/${id}`,
  DASHBOARD: `/dashboard/admin/dashboard`,
  ADD_ROLE: `/users/role/add`,
  DELETE_ROLE: (id) => `/users/role/${id}`,
  GET_ROLE_BY_ID: (id) => `/users/role/${id}`,
  UPDATE_ROLE: (id) => `/users/role/edit/${id}`,
  GET_ALL_SUB_USERS: `/users/subadmin/all`,
  DELETE_SUB_USERS: (id) => `/users/subadmin/${id}`,
  GET_USER_BY_ID: (id) => `/users/subadmin/${id}`,
  ADD_SUB_USERS: `/users/subadmin/add`,
  UPDATE_SUB_USERS: (id) => `/users/subadmin/edit/${id}`,
  GET_CUISINE: `/cuisines/get-all-cuisine`,
  UPLOAD_IMAGE_TO_CATEGORY: `/store-category-realtion/update-category-image`,
  GET_ORDER_HISTORY: `/customers/get-customers-orders`,
  GET_ALL_CANCELLATION_REASON: `/order-cancellation-reason/get-all`,
  GET_ALL_CANCELLATION_DEPARTMENT: `/order-cancellation-department/get-all`,
  ADD_CANCELLATION_REASON: `/order-cancellation-reason/add`,
  ADD_CANCELLATION_DEPARTMENT: `/order-cancellation-department/add`,
  DELETE_CANCELLATION_REASON: (id) => `/order-cancellation-reason/delete/${id}`,
  DELETE_CANCELLATION_DEPARTMENT: (id) => `/order-cancellation-department/delete/${id}`,
  UPDATE_CANCELLATION_REASON: (id) => `/order-cancellation-reason/edit/${id}`,
  UPDATE_CANCELLATION_DEPARTMENT: (id) => `/order-cancellation-department/edit/${id}`,
  UPDATE_CANCELLATION_REASON_STATUS: (id) => `/order-cancellation-reason/update-status/${id}`,
  UPDATE_CANCELLATION_DEPARTMENT_STATUS: (id) => `/order-cancellation-department/update-status/${id}`,
  GET_ALL_SETTINGS: `/admin-settings/get-all`,
  UPDATE_SETTING_VALUE: (id) => `/admin-settings/edit/${id}`,
  CATEGORIES_STORE_CREATE_JSON: (id) => `/category/create-store-category-json/${id}`,
  GET_SETTING_VALUE: (id) => `/admin-settings/get-setting-by-name/${id}`,
  CREATE_TOOKAN_TASK: (id) => `/orders/admin/creat-task-on-tookan/${id}`,
  GET_BANNER_SPECIFIC: (id) => `/site-setting/get-banner-list-detail/${id}`,
  STOCK_LOGS: (id) => `/admin/stock-ledger/get-all/${id}`,
  GET_ALL_BRANDS_DROPDOWN: `/stores/brand-list-dropdown`,
  GET_ALL_STORES_BU_BRAND: (id) => `/stores/store-list-by-brand/${id}`,
  UPDATE_PRODUCT_STORE_STATUS: `/products/update-product-status-for-stores-adm`,
  UPDATE_UOM_STORE_STATUS: `/products/update-uom-status-for-stores-adm`,
  UPDATE_CATEGORY_TYPE: `/category/update-category-type`,
  UPDATE_CATEGORY_BUSINESS_PAGE: `/category/add-category-to-business-landing`,

  CUISINE: {
    GET_ALL_BUSINESS_TYPE: "/business-type/get-all-business-type",
    ADD: "/cuisines/add",
    EDIT: "/cuisines/edit",
    DELETE: (id) => `/cuisines/delete/${id}`,
    UPDATE_STATUS: "/cuisines/update-status",
    GET_ALL_CUISINE: `/cuisines/get-all-cuisine-admin`,
    ADD_ASSOCIATES: `/cuisines/add-associates`,
    ADD_CATEGORY: `/cuisines/add-category`,
    EDIT_CATEGORY: `/cuisines/edit-category`,
    DELETE_CATEGORY: (id) => `/cuisines/delete-category/${id}`,
    UPDATE_CATEGORY_STATUS: "/cuisines/category-update-status",
    GET_ALL_CATEGORY: `/cuisines/get-cuisine-category`,
    GET_CUISINE_USING_CATEGORY_ID: `/cuisines/get-cuisine-using-category-id`,
    GET_ALL_CUS: `/cuisines/get-all-cuisine`,
    GET_ALL_CUISINE_business: (id) => `/business-type/business-cuisine-list/${id}`,
    SORT_CUISINE: `/business-type/cuisine-sort`
  },
  TRAFFIC_LIGHT_SYSTEM: {
    GET_ALL: `/traffic-light-system/get-all`,
    UPDATE_ALL: `/traffic-light-system/update-all`,
  },
  PICKER_TRACKING: {
    GET_PICKER_ORDER: `/picker-tracker/stats`,
    GET_NOTPICKER_ORDER: (query) => `/picker-tracker/get-PickerStatus?${query}`,
    GET_PICKERS: (query) => `/picker-tracker/get-all-Picker?${query}`,
    // GET_SPECIFICPICK_ORDER: (id) => `/picker-tracker/specific-order/${id}`,
    // GET_PICKER_IDLE: (query) => `/picker-tracker/get-idle-picker?${query}`,
    GET_PICKER_IDLE: (query) => `/picker-tracker/get-all-picker-list?${query}`,
    GET_TOP_5PICKER: (query) => `/picker-tracker/get-top-Picker?${query}`,
    GET_PICKERS_ORDER: (id, query) => `/picker-tracker/get-picker-order/${id}?${query}`,
    Details_Delayed: (id) => `/picker-tracker/get-picker-last-order/${id}`,
    GET_DELAY: (query) => `/picker-tracker/get-delayed-picker?${query}`,
    GET_PICKERS_COLOR: `/picker-tracker/get`,
  },
  TRAFFIC_LIGHT: {
    GET_TIME: "/picker-tracker/get",
    DELETE_TRAFFIC_LIGHT: (id) => `/picker-tracker/delete/${id}`,
    Create_Traffic: `/picker-tracker/add`,
    GET_Time_Start_Picking: `/picker-tracker/get-starttime`,
    Update_Time_Start_Picking: `/picker-tracker/edit-starttime`,
  },
  Driver: {
    GET_ALL_DRIVER: `/finance/get-all-driver`,
  },
  DISCOUNT_AND_OFFERS: {
    GET_ALL: `/discounts-and-offers/all`,
    DELETE_DISCOUNT_AND_OFFERS: (id) => `/discounts-and-offers/update-status/${id}`,
    UPLOAD_IMAGE: `/discounts-and-offers/upload-image`,
    ADD: `/discounts-and-offers/add`,
    EDIT: (id) => `/discounts-and-offers/edit/${id}`,
    GET_EDIT_DATA: (id) => `/discounts-and-offers/specific/${id}`,
    DELETE_DISCOUNT_AND_OFFERS: (id) => `/discounts-and-offers/update-status/${id}`,
  },
  DASHBOARD: {
    PRODUCT_COUNT: `/dashboard/admin/dashboard/productCount`,
    ORDERS_COUNT: `/dashboard/admin/dashboard/orderCount`,
    DRIVER_COUNT: `/dashboard/admin/dashboard/otherCount`,
    REVENUE: `/dashboard/admin/dashboard/totalRevenue`,
  },
  STORE_REQUEST: {
    APPROVE: (id) => `/users/approve-store/${id}`,
  },
  BANNER: {
    ADD_BANNER_NEW: `/site-setting/add`,
    UPDATE_BANNER_NEW: (bannerId) => `/site-setting/edit/${bannerId}`
  },
  BUSINESS_TYPE : {
    SORT_BUSINESS_TYPE : `/business-type/update-sort-business-type`,
  },
  STORE_RANKING : {
    GET_ALL_BRANDS : (id) => `/admin/store-ranking/get-all-brand/${id}`,
    GET_ALL_BRANDS_RANKING : (id) => `/admin/store-ranking/get-all-brand-rankings/${id}`,
    CREATE_BRANDS_RANKING :  `/admin/store-ranking/create-brand-rankings`,
    DELETE_BRANDS_RANKING : `/admin/store-ranking/delete-brand-rankings`,
  }, 
  WIDGET: {
    GET_ALL_BRANDS: (business_type) => `/admin/widget/get-all-brand/${business_type}`,
    GET_ALL_BANNERS: (business_type) => `/admin/widget/get-all-banners/${business_type}`,
    GET_ALL_BANNERS_HOME: `/admin/widget/get-all-banners`,
    GET_ALL_CATEGORIES: (business_type) => `/admin/widget/get-all-categories/${business_type}`,
    GET_ALL_PRODUCTS: (brand_id) => `/admin/widget/get-all-products/${brand_id}`,
    GET_ALL_WIDGETS: (widgetType) => `/admin/widget/get-all-widget/${widgetType}`,
    UPLOAD_ICON: `/admin/widget/upload-widget-icon`,
    GET_ALL_BUSINESS_WIDGETS: `/admin/widget/get-all-business-type-widget`,
    CREATE_BUSINESS_WIDGETS: `/admin/widget/create-business-type-widget`,
    DELETE_BUSINESS_WIDGETS: `/admin/widget/delete-widget-items`,
    UPDATE_ALL_WIDGET: `/admin/widget/update-widget`,
    UPDATE_WIDGET_STATUS: (widgetId) => `/admin/widget/update-widget-status/${widgetId}`,
    UPLOAD_IMAGE: `/admin/widget/upload-widget-image`,
  },
  TAGS: {
    GET:  `/admin/sarching-tag/get-list`,
    ADD:  `/admin/sarching-tag/add-tag`,
    UPDATE:  (id) => `/admin/sarching-tag/update-tag/${id}`,
    DELETE:  (id) => `/admin/sarching-tag/delete-tag/${id}`,
  }
};
